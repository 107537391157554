import React, { useState, useEffect } from 'react';
import { 
    TrendingUp, 
    TrendingDown, 
    Search, 
    RefreshCcw,
    ArrowUpDown,
    ArrowUp,
    ArrowDown
} from 'lucide-react';
import Navigation from './Navigation';
import { fetchOpenPositions, fetchClosedPositions } from '../services/api';

export default function PositionsTable() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [positions, setPositions] = useState([]);
    const [availableIntervals, setAvailableIntervals] = useState([]);
    const [filters, setFilters] = useState({
        type: 'all',
        status: 'closed',
        interval: 'all',
        searchTerm: '',
        showOnlyReal: false
    });
    const [sortConfig, setSortConfig] = useState({
        key: 'created_at',
        direction: 'desc'
    });

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 30000);
        return () => clearInterval(interval);
    }, [filters.status]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            let fetchedPositions;
            if (filters.status === 'closed') {
                const result = await fetchClosedPositions();
                fetchedPositions = result.positions;
            } else {
                const result = await fetchOpenPositions();
                fetchedPositions = result.positions;
            }
            setPositions(fetchedPositions);
            
            const uniqueIntervals = [...new Set(fetchedPositions.map(p => p.time_interval))].sort();
            setAvailableIntervals(uniqueIntervals);

            if (filters.interval !== 'all' && !uniqueIntervals.includes(filters.interval)) {
                setFilters(prev => ({ ...prev, interval: 'all' }));
            }
        } catch (error) {
            setError(error.message);
            console.error('Error fetching positions:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const SortIcon = ({ column }) => {
        if (sortConfig.key !== column) {
            return <ArrowUpDown className="w-4 h-4 text-gray-500" />;
        }
        return sortConfig.direction === 'asc' ? 
            <ArrowUp className="w-4 h-4 text-blue-400" /> : 
            <ArrowDown className="w-4 h-4 text-blue-400" />;
    };

    const formatProfit = (profit, percentage = false) => {
        if (profit === undefined || profit === null) {
            return (
                <div className="text-gray-400">
                    <span>$0.00</span>
                </div>
            );
        }
    
        const numericProfit = Number(profit);
        
        if (isNaN(numericProfit)) {
            return (
                <div className="text-gray-400">
                    <span>$0.00</span>
                </div>
            );
        }
    
        const value = percentage ? 
            `${numericProfit.toFixed(2)}%` : 
            `$${numericProfit.toFixed(2)}`;
        
        const isPositive = numericProfit > 0;
        
        return (
            <div className={`flex items-center gap-1 ${isPositive ? 'text-green-400' : 'text-red-400'}`}>
                {isPositive ? <TrendingUp className="w-4 h-4" /> : <TrendingDown className="w-4 h-4" />}
                <span>{value}</span>
            </div>
        );
    };

    const filteredAndSortedPositions = positions
        .filter(position => {
            const matchesSearch = position.asset.toLowerCase().includes(filters.searchTerm.toLowerCase());
            const matchesType = filters.type === 'all' || position.position_type === filters.type;
            const matchesInterval = filters.interval === 'all' || position.time_interval === filters.interval;
            const matchesReal = !filters.showOnlyReal || position.is_real_trade;
            return matchesSearch && matchesType && matchesInterval && matchesReal;
        })
        .sort((a, b) => {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];
            const direction = sortConfig.direction === 'asc' ? 1 : -1;

            if (typeof aValue === 'string') {
                return direction * aValue.localeCompare(bValue);
            }
            return direction * (aValue - bValue);
        });

    return (
        <div className="min-h-screen bg-gray-900">
            <Navigation />
            <div className="p-8">
                <div className="bg-gray-800 rounded-lg shadow-lg border border-gray-700">
                    {/* Header and Filters */}
                    <div className="p-6 border-b border-gray-700">
                        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                            <h2 className="text-2xl font-bold text-gray-100">Trading Positions</h2>
                            <div className="flex items-center gap-2">
                                <span className="px-3 py-1 bg-gray-700 rounded-full text-sm font-medium text-gray-300">
                                    {filteredAndSortedPositions.length} positions
                                </span>
                                {filters.status === 'closed' && (
                                    <>
                                        <span className="px-3 py-1 bg-green-900/50 rounded-full text-sm font-medium text-green-400">
                                            {filteredAndSortedPositions.filter(p => p.status === 'win').length} wins
                                        </span>
                                        <span className="px-3 py-1 bg-red-900/50 rounded-full text-sm font-medium text-red-400">
                                            {filteredAndSortedPositions.filter(p => p.status === 'lose').length} losses
                                        </span>
                                    </>
                                )}
                            </div>
                            
                            <div className="flex flex-wrap items-center gap-4">
                                {/* Search */}
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search symbol..."
                                        value={filters.searchTerm}
                                        onChange={(e) => setFilters(prev => ({ ...prev, searchTerm: e.target.value }))}
                                        className="bg-gray-700 text-gray-100 px-4 py-2 pl-10 rounded-lg border border-gray-600 focus:outline-none focus:border-blue-500"
                                    />
                                    <Search className="w-4 h-4 text-gray-400 absolute left-3 top-3" />
                                </div>

                                {/* Status Filter */}
                                <select
                                    value={filters.status}
                                    onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                                    className="bg-gray-700 text-gray-100 px-4 py-2 rounded-lg border border-gray-600 focus:outline-none focus:border-blue-500"
                                >
                                    <option value="closed">Closed Positions</option>
                                    <option value="open">Open Positions</option>
                                </select>

                                {/* Type Filter */}
                                <select
                                    value={filters.type}
                                    onChange={(e) => setFilters(prev => ({ ...prev, type: e.target.value }))}
                                    className="bg-gray-700 text-gray-100 px-4 py-2 rounded-lg border border-gray-600 focus:outline-none focus:border-blue-500"
                                >
                                    <option value="all">All Types</option>
                                    <option value="Long">Long</option>
                                    <option value="Short">Short</option>
                                </select>

                                {/* Interval Filter */}
                                <select
                                    value={filters.interval}
                                    onChange={(e) => setFilters(prev => ({ ...prev, interval: e.target.value }))}
                                    className="bg-gray-700 text-gray-100 px-4 py-2 rounded-lg border border-gray-600 focus:outline-none focus:border-blue-500"
                                >
                                    <option value="all">All Intervals</option>
                                    {availableIntervals.map(interval => (
                                        <option key={interval} value={interval}>
                                            {interval}
                                        </option>
                                    ))}
                                </select>

                                {/* Real Trade Filter */}
                                <label className="flex items-center space-x-2 text-gray-300">
                                    <input
                                        type="checkbox"
                                        checked={filters.showOnlyReal}
                                        onChange={(e) => setFilters(prev => ({ ...prev, showOnlyReal: e.target.checked }))}
                                        className="form-checkbox h-4 w-4 text-blue-500 rounded border-gray-600 bg-gray-700"
                                    />
                                    <span>Real Trades Only</span>
                                </label>

                                {/* Refresh Button */}
                                <button
                                    onClick={fetchData}
                                    className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg flex items-center gap-2"
                                >
                                    <RefreshCcw className="w-4 h-4" />
                                    Refresh
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Table */}
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="text-gray-400 border-b border-gray-700">
                                    <th className="p-4 text-left">
                                        <button 
                                            onClick={() => handleSort('asset')} 
                                            className="flex items-center gap-1"
                                        >
                                            Symbol <SortIcon column="asset" />
                                        </button>
                                    </th>
                                    <th className="p-4 text-left">Type</th>
                                    <th className="p-4 text-left">
                                        <button 
                                            onClick={() => handleSort('time_interval')} 
                                            className="flex items-center gap-1"
                                        >
                                            Interval <SortIcon column="time_interval" />
                                        </button>
                                    </th>
                                    <th className="p-4 text-right">
                                        <button 
                                            onClick={() => handleSort('entry_price')} 
                                            className="flex items-center gap-1"
                                        >
                                            Entry Price <SortIcon column="entry_price" />
                                        </button>
                                    </th>
                                    {filters.status === 'closed' && (
                                        <th className="p-4 text-right">Exit Price</th>
                                    )}
                                    <th className="p-4 text-right">Stop Loss</th>
                                    <th className="p-4 text-right">Take Profit</th>
                                    {filters.status === 'closed' && (
                                        <th className="p-4 text-right">
                                            <button 
                                                onClick={() => handleSort('profit')} 
                                                className="flex items-center gap-1"
                                            >
                                                Profit <SortIcon column="profit" />
                                            </button>
                                        </th>
                                    )}
                                    <th className="p-4 text-center">Status</th>
                                    <th className="p-4 text-right">
                                        <button 
                                            onClick={() => handleSort('created_at')} 
                                            className="flex items-center gap-1"
                                        >
                                            Date <SortIcon column="created_at" />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-300">
                                {isLoading ? (
                                    <tr>
                                        <td colSpan="10" className="text-center p-4">
                                            <div className="flex justify-center items-center gap-2">
                                                <RefreshCcw className="w-5 h-5 animate-spin" />
                                                Loading positions...
                                            </div>
                                        </td>
                                    </tr>
                                ) : filteredAndSortedPositions.length === 0 ? (
                                    <tr>
                                        <td colSpan="10" className="text-center p-4">
                                            No positions found
                                        </td>
                                    </tr>
                                ) : (
                                    filteredAndSortedPositions.map((position) => (
                                        <tr key={position.position_id} className="border-b border-gray-700 hover:bg-gray-750">
                                            <td className="p-4 font-medium">
                                                <div className="flex items-center gap-2">
                                                    {position.asset}
                                                    {position.is_real_trade === 1 && (
                                                        <span className="px-2 py-1 text-xs font-semibold rounded-full bg-green-900/50 text-green-400">
                                                            Real
                                                        </span>
                                                    )}
                                                </div>
                                            </td>
                                            <td className={`p-4 ${position.position_type === 'Long' ? 'text-green-400' : 'text-red-400'}`}>
                                                {position.position_type}
                                            </td>
                                            <td className="p-4">{position.time_interval}</td>
                                            <td className="p-4 text-right">${position.entry_price}</td>
                                            {filters.status === 'closed' && (
                                                <td className="p-4 text-right">${position.exit_price}</td>
                                            )}
                                            <td className="p-4 text-right text-red-400">${position.stop_loss_price}</td>
                                            <td className="p-4 text-right text-green-400">${position.take_profit_price}</td>
                                            {filters.status === 'closed' && (
                                                <td className="p-4 text-right">
                                                    {formatProfit(position.profit)}
                                                </td>
                                            )}
                                            <td className="p-4 text-center">
                                                <span className={`px-2 py-1 rounded-full text-xs font-semibold 
                                                    ${position.status === 'win' 
                                                        ? 'bg-green-900/50 text-green-400' 
                                                        : position.status === 'lose'
                                                            ? 'bg-red-900/50 text-red-400'
                                                            : 'bg-blue-900/50 text-blue-400'
                                                    }`}>
                                                    {position.status.toUpperCase()}
                                                </span>
                                            </td>
                                            <td className="p-4 text-right">
                                                {new Date(position.created_at).toLocaleString()}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}