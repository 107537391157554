import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import PositionsTable from './components/PositionsTable';
import Statistics from './components/Statistics';
import ConfigEditor from './components/ConfigEditor';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/positions" element={<PositionsTable />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="/config" element={<ConfigEditor />} />
                {/* Redirect any unknown routes to Dashboard */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default App;