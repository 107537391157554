import React, { useState, useEffect } from 'react';
import { 
    LineChart, 
    BarChart as RechartsBarChart,
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Line, 
    Bar,
    Tooltip, 
    ResponsiveContainer,
    PieChart as RechartsPieChart,
    Pie,
    Cell
} from 'recharts';
import { 
    TrendingUp, 
    TrendingDown, 
    DollarSign, 
    Clock, 
    BarChart
} from 'lucide-react';
import Navigation from './Navigation';
import { 
    fetchDashboardSummary, 
    fetchPerformanceStats,
    fetchTradeStats,
    fetchTimeFrameStats,
    fetchStrategyStats 
} from '../services/api';

export default function Statistics() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState({
        summary: {
            totalTrades: 0,
            winRate: 0,
            avgProfit: 0,
            profitFactor: 0
        },
        performance: [],
        timeDistribution: [],
        strategyPerformance: []
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const [tradeStats, performance, timeFrames, strategies] = await Promise.all([
                fetchTradeStats(),
                fetchPerformanceStats(),
                fetchTimeFrameStats(),
                fetchStrategyStats()
            ]);

            setData({
                summary: tradeStats,
                performance,
                timeDistribution: timeFrames,
                strategyPerformance: strategies
            });
        } catch (error) {
            setError(error.message);
            console.error('Error fetching statistics:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const StatCard = ({ title, value, icon: Icon, trend, subtitle }) => (
        <div className="bg-gray-800 rounded-lg shadow-lg p-6 border border-gray-700">
            <div className="flex justify-between items-start">
                <div>
                    <p className="text-sm text-gray-400">{title}</p>
                    <p className="text-2xl font-bold text-gray-100 mt-1">{value}</p>
                    {subtitle && (
                        <p className="text-sm text-gray-500 mt-1">{subtitle}</p>
                    )}
                </div>
                <div className={`p-3 rounded-full ${
                    trend === 'up' 
                        ? 'bg-green-900/50' 
                        : trend === 'down' 
                            ? 'bg-red-900/50' 
                            : 'bg-blue-900/50'}`}>
                    <Icon className={`w-6 h-6 ${
                        trend === 'up' 
                            ? 'text-green-400' 
                            : trend === 'down' 
                                ? 'text-red-400' 
                                : 'text-blue-400'}`} />
                </div>
            </div>
        </div>
    );

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-gray-800 border border-gray-700 p-4 rounded-lg shadow-lg">
                    <p className="text-gray-300">{payload[0].payload.timeframe}</p>
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: entry.color }} className="font-bold">
                            {`${entry.name === 'wins' ? 'Wins' : entry.name}: ${entry.value}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const COLORS = ['#22c55e', '#ef4444', '#3b82f6', '#f59e0b'];

    if (error) {
        return (
            <div className="min-h-screen bg-gray-900">
                <Navigation />
                <div className="p-8">
                    <div className="text-red-400 bg-red-900/30 p-4 rounded-lg border border-red-700">
                        <h2 className="text-lg font-bold mb-2">Error Loading Statistics</h2>
                        <p>{error}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-900">
            <Navigation />
            <div className="p-8">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-100">Trading Statistics</h1>
                    {isLoading && (
                        <div className="text-blue-400">
                            Loading statistics...
                        </div>
                    )}
                </div>

                {/* Top Stats */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                    <StatCard
                        title="Total Trades"
                        value={data.summary.totalTrades}
                        icon={BarChart}
                        subtitle="Lifetime trades"
                    />
                    <StatCard
                        title="Win Rate"
                        value={`${data.summary.winRate}%`}
                        icon={TrendingUp}
                        trend="up"
                        subtitle="Success rate"
                    />
                    <StatCard
                        title="Average Profit"
                        value={`$${data.summary.avgProfit}`}
                        icon={DollarSign}
                        trend="up"
                        subtitle="Per winning trade"
                    />
                    <StatCard
                        title="Profit Factor"
                        value={data.summary.profitFactor}
                        icon={TrendingUp}
                        trend="up"
                        subtitle="Risk/Reward ratio"
                    />
                </div>

                {/* Charts Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    {/* Cumulative Performance */}
                    <div className="bg-gray-800 rounded-lg shadow-lg p-6 border border-gray-700">
                        <h2 className="text-xl font-bold text-gray-100 mb-4">Cumulative Performance</h2>
                        <div className="h-80">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={data.performance}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                                    <XAxis dataKey="period" stroke="#9CA3AF" />
                                    <YAxis stroke="#9CA3AF" />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Line 
                                        type="monotone" 
                                        dataKey="profit"
                                        name="Profit"
                                        stroke="#3B82F6"
                                        strokeWidth={2}
                                        dot={false}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    {/* Strategy Performance */}
                    <div className="bg-gray-800 rounded-lg shadow-lg p-6 border border-gray-700">
                        <h2 className="text-xl font-bold text-gray-100 mb-4">Strategy Performance</h2>
                        <div className="h-80">
                            <ResponsiveContainer width="100%" height="100%">
                                <RechartsBarChart data={data.strategyPerformance}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                                    <XAxis dataKey="strategy" stroke="#9CA3AF" />
                                    <YAxis stroke="#9CA3AF" />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Bar dataKey="wins" name="Wins" fill="#22c55e" stackId="a" />
                                    <Bar dataKey="losses" name="Losses" fill="#ef4444" stackId="a" />
                                </RechartsBarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>

                {/* Time Distribution & Details */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                    {/* Time Distribution Pie Chart */}
                    <div className="bg-gray-800 rounded-lg shadow-lg p-6 border border-gray-700">
                        <h2 className="text-xl font-bold text-gray-100 mb-4">Time Frame Distribution</h2>
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <RechartsPieChart>
                                    <Pie
                                        data={data.timeDistribution}
                                        nameKey="timeframe"
                                        dataKey="wins"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                        label
                                    >
                                        {data.timeDistribution.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<CustomTooltip />} />
                                </RechartsPieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    {/* Additional Stats */}
                    <div className="lg:col-span-2 bg-gray-800 rounded-lg shadow-lg p-6 border border-gray-700">
                        <h2 className="text-xl font-bold text-gray-100 mb-4">Detailed Statistics</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <p className="text-gray-400 mb-2">Largest Win</p>
                                <p className="text-2xl font-bold text-green-400">
                                    ${data.summary.largestWin}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray-400 mb-2">Largest Loss</p>
                                <p className="text-2xl font-bold text-red-400">
                                    ${data.summary.largestLoss}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray-400 mb-2">Average Win</p>
                                <p className="text-2xl font-bold text-green-400">
                                    ${data.summary.avgProfit}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray-400 mb-2">Average Loss</p>
                                <p className="text-2xl font-bold text-red-400">
                                    ${data.summary.avgLoss}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}