import React, { useState, useEffect } from 'react';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, ResponsiveContainer } from 'recharts';
import { Activity, AlertTriangle, DollarSign, TrendingUp, TrendingDown } from 'lucide-react';
import Navigation from './Navigation';
import { 
    fetchDashboardSummary, 
    fetchPerformanceStats,
    fetchOpenPositions
} from '../services/api';

export default function Dashboard() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [data, setData] = useState({
        summary: {
            totalPositions: 0,
            openPositions: 0,
            totalProfit: 0,
            winRate: 0
        },
        performance: [],
        openPositions: []
    });

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 30000); // רענון כל 30 שניות
        
        return () => clearInterval(interval);
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const [summary, performance, positions] = await Promise.all([
                fetchDashboardSummary(),
                fetchPerformanceStats('1d'),
                fetchOpenPositions()
            ]);

            setData({
                summary,
                performance,
                openPositions: positions.positions
            });
            setLastUpdate(new Date().toLocaleTimeString());
        } catch (err) {
            setError(err.message);
            console.error('Error fetching dashboard data:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const StatCard = ({ title, value, icon: Icon, trend, subtitle }) => (
        <div className="bg-gray-800 rounded-lg shadow-lg p-6 border border-gray-700">
            <div className="flex justify-between items-start">
                <div>
                    <p className="text-sm text-gray-400">{title}</p>
                    <p className="text-2xl font-bold text-gray-100 mt-1">{value}</p>
                    {subtitle && (
                        <p className="text-sm text-gray-500 mt-1">{subtitle}</p>
                    )}
                </div>
                <div className={`p-3 rounded-full ${
                    trend === 'up' 
                        ? 'bg-green-900/50' 
                        : trend === 'down' 
                            ? 'bg-red-900/50' 
                            : 'bg-blue-900/50'}`}>
                    <Icon className={`w-6 h-6 ${
                        trend === 'up' 
                            ? 'text-green-400' 
                            : trend === 'down' 
                                ? 'text-red-400' 
                                : 'text-blue-400'}`} />
                </div>
            </div>
        </div>
    );

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-gray-800 border border-gray-700 p-4 rounded-lg shadow-lg">
                    <p className="text-gray-300">{`${label}`}</p>
                    <p className="text-gray-100 font-bold">
                        {`Profit: $${payload[0].value.toFixed(2)}`}
                    </p>
                </div>
            );
        }
        return null;
    };

    if (error) {
        return (
            <div className="min-h-screen bg-gray-900">
                <Navigation />
                <div className="p-8">
                    <div className="text-red-400 bg-red-900/30 p-4 rounded-lg border border-red-700">
                        <h2 className="text-lg font-bold mb-2">Error Loading Dashboard</h2>
                        <p>{error}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-900">
            <Navigation />
            <div className="p-8">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-100">Trading Dashboard</h1>
                    <div className="flex items-center gap-4">
                        {isLoading && (
                            <div className="text-blue-400">
                                Refreshing data...
                            </div>
                        )}
                        {lastUpdate && (
                            <div className="text-gray-400 text-sm">
                                Last update: {lastUpdate}
                            </div>
                        )}
                    </div>
                </div>
                
                {/* Stats Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                    <StatCard
                        title="Total Positions"
                        value={data.summary.totalPositions}
                        icon={Activity}
                    />
                    <StatCard
                        title="Open Positions"
                        value={data.summary.openPositions}
                        icon={TrendingUp}
                        trend="up"
                    />
                    <StatCard
                        title="Total Profit"
                        value={`$${data.summary.totalProfit.toFixed(2)}`}
                        icon={DollarSign}
                        trend={data.summary.totalProfit >= 0 ? 'up' : 'down'}
                    />
                    <StatCard
                        title="Win Rate"
                        value={`${data.summary.winRate}%`}
                        icon={TrendingUp}
                        trend={data.summary.winRate >= 50 ? 'up' : 'down'}
                    />
                </div>

                {/* Charts and Alerts Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                    {/* Performance Chart */}
                    <div className="lg:col-span-2 bg-gray-800 rounded-lg shadow-lg p-6 border border-gray-700">
                        <h2 className="text-xl font-bold text-gray-100 mb-4">Performance</h2>
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={data.performance}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                                    <XAxis 
                                        dataKey="period"
                                        stroke="#9CA3AF"
                                        tick={{ fill: '#9CA3AF' }}
                                    />
                                    <YAxis 
                                        stroke="#9CA3AF"
                                        tick={{ fill: '#9CA3AF' }}
                                    />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Line 
                                        type="monotone" 
                                        dataKey="profit"
                                        name="Profit"
                                        stroke="#3B82F6"
                                        strokeWidth={2}
                                        dot={{ fill: '#3B82F6', strokeWidth: 2 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    {/* Active Positions */}
                    <div className="bg-gray-800 rounded-lg shadow-lg p-6 border border-gray-700">
                        <div className="flex items-center mb-4">
                            <AlertTriangle className="w-5 h-5 text-yellow-400 mr-2" />
                            <h2 className="text-xl font-bold text-gray-100">Active Positions</h2>
                        </div>
                        <div className="space-y-4 max-h-[400px] overflow-y-auto">
                            {data.openPositions.length === 0 ? (
                                <div className="text-gray-400">
                                    No active positions
                                </div>
                            ) : (
                                data.openPositions.map((position) => (
                                    <div key={position.position_id} 
                                        className={`p-4 rounded-lg border ${position.is_real_trade ? 
                                            'bg-green-900/30 text-green-400 border-green-700' : 
                                            'bg-blue-900/30 text-blue-400 border-blue-700'}`}>
                                        <div className="font-bold">
                                            {position.asset} - {position.position_type}
                                        </div>
                                        <div className="text-sm">
                                            Entry: ${position.entry_price}
                                        </div>
                                        <div className="text-sm">
                                            {position.is_real_trade ? 'Real Trade' : 'Virtual Trade'}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}