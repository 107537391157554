import React, { useState } from 'react';
import Navigation from './Navigation';
import { RefreshCcw } from 'lucide-react';

export default function ConfigEditor() {
    const [config, setConfig] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState(null);

    const fetchConfig = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`https://trade.livenet.co.il:1511/api/config?password=${password}`);
            if (response.status === 401) {
                setError('Invalid password');
                return;
            }
            const data = await response.json();
            setConfig(data.content);
            setIsAuthenticated(true);
        } catch (error) {
            setError('Failed to load config');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async () => {
        try {
            // בדיקה שהקובץ הוא JS תקין
            const testFunction = new Function(config);
            
            const response = await fetch('https://trade.livenet.co.il:1511/api/config', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password, content: config })
            });

            if (response.status === 401) {
                alert('Invalid password');
                return;
            }

            alert('Config saved successfully');
        } catch (error) {
            alert('Invalid JavaScript code format');
        }
    };

    return (
        <div className="min-h-screen bg-gray-900">
            <Navigation />
            <div className="p-8">
                <div className="bg-gray-800 rounded-lg shadow-lg border border-gray-700">
                    <div className="p-6">
                        {!isAuthenticated ? (
                            <div className="flex flex-col items-center gap-4">
                                <h2 className="text-2xl font-bold text-gray-100">Enter Password</h2>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && fetchConfig()}
                                    placeholder="Enter password"
                                    className="bg-gray-700 text-white px-3 py-2 rounded border border-gray-600"
                                    autoFocus
                                />
                                <button
                                    onClick={fetchConfig}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                >
                                    Submit
                                </button>
                                {error && <div className="text-red-400">{error}</div>}
                            </div>
                        ) : (
                            <>
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-2xl font-bold text-gray-100">Configuration</h2>
                                    <div className="flex gap-2">
                                        <button
                                            onClick={() => {
                                                setIsAuthenticated(false);
                                                setPassword('');
                                                setConfig('');
                                            }}
                                            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                                        >
                                            Logout
                                        </button>
                                        <button
                                            onClick={handleSave}
                                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                                {isLoading ? (
                                    <div className="flex justify-center items-center">
                                        <RefreshCcw className="w-6 h-6 animate-spin text-blue-500" />
                                    </div>
                                ) : (
                                    <textarea
                                        value={config}
                                        onChange={(e) => setConfig(e.target.value)}
                                        className="w-full h-[600px] bg-gray-700 text-gray-100 p-4 rounded font-mono"
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}