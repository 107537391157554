const BASE_URL = 'https://trade.livenet.co.il:1511';

export async function fetchDashboardSummary() {
    try {
        const response = await fetch(`${BASE_URL}/api/dashboard/summary`);
        if (!response.ok) throw new Error('Failed to fetch dashboard summary');
        return await response.json();
    } catch (error) {
        console.error('Error fetching dashboard summary:', error);
        throw error;
    }
}

export async function fetchPerformanceStats(timeframe = '1d', from = null, to = null) {
    try {
        const params = new URLSearchParams({
            timeframe,
            ...(from && { from }),
            ...(to && { to })
        });
        const response = await fetch(`${BASE_URL}/api/statistics/performance?${params}`);
        if (!response.ok) throw new Error('Failed to fetch performance stats');
        return await response.json();
    } catch (error) {
        console.error('Error fetching performance stats:', error);
        throw error;
    }
}

export async function fetchTradeStats() {
    try {
        const response = await fetch(`${BASE_URL}/api/statistics/trades`);
        if (!response.ok) throw new Error('Failed to fetch trade stats');
        return await response.json();
    } catch (error) {
        console.error('Error fetching trade stats:', error);
        throw error;
    }
}

export async function fetchTimeFrameStats() {
    try {
        const response = await fetch(`${BASE_URL}/api/statistics/timeframes`);
        if (!response.ok) throw new Error('Failed to fetch timeframe stats');
        return await response.json();
    } catch (error) {
        console.error('Error fetching timeframe stats:', error);
        throw error;
    }
}

export async function fetchStrategyStats() {
    try {
        const response = await fetch(`${BASE_URL}/api/statistics/strategies`);
        if (!response.ok) throw new Error('Failed to fetch strategy stats');
        return await response.json();
    } catch (error) {
        console.error('Error fetching strategy stats:', error);
        throw error;
    }
}

export async function fetchOpenPositions() {
    try {
        const response = await fetch(`${BASE_URL}/pumpdump/positions/open`);
        if (!response.ok) throw new Error('Failed to fetch open positions');
        return await response.json();
    } catch (error) {
        console.error('Error fetching open positions:', error);
        throw error;
    }
}

export async function fetchClosedPositions(filters = {}) {
    try {
        const params = new URLSearchParams({
            ...(filters.from_date && { from_date: filters.from_date }),
            ...(filters.to_date && { to_date: filters.to_date }),
            ...(filters.position_type && { position_type: filters.position_type })
        });
        const response = await fetch(`${BASE_URL}/pumpdump/positions/closed?${params}`);
        if (!response.ok) throw new Error('Failed to fetch closed positions');
        return await response.json();
    } catch (error) {
        console.error('Error fetching closed positions:', error);
        throw error;
    }
}