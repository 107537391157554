import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

export default function Navigation() {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [showRestartModal, setShowRestartModal] = useState(false);
    const [restartPassword, setRestartPassword] = useState('');
    
    const isActive = (path) => {
        return location.pathname === path 
            ? 'bg-gray-700 text-white' 
            : 'text-gray-300 hover:bg-gray-700 hover:text-white';
    };

    const handleRestart = async () => {
        try {
            const response = await fetch('https://trade.livenet.co.il:1511/api/system/restart', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: restartPassword })
            });
    
            if (response.status === 401) {
                alert('Invalid password');
                return;
            }
    
            const data = await response.json();
            alert(data.message);
            setShowRestartModal(false);
            setRestartPassword('');
            
        } catch (error) {
            alert('Failed to restart server');
            console.error('Restart error:', error);
        }
    };

    const NavLinks = () => (
        <>
            <Link 
                to="/"
                className={`${isActive('/')} rounded-md px-3 py-2 text-sm font-medium transition-colors duration-200`}
                aria-current={location.pathname === '/' ? 'page' : undefined}
            >
                Dashboard
            </Link>
            <Link 
                to="/positions"
                className={`${isActive('/positions')} rounded-md px-3 py-2 text-sm font-medium transition-colors duration-200`}
                aria-current={location.pathname === '/positions' ? 'page' : undefined}
            >
                Positions
            </Link>
            <Link 
                to="/statistics"
                className={`${isActive('/statistics')} rounded-md px-3 py-2 text-sm font-medium transition-colors duration-200`}
                aria-current={location.pathname === '/statistics' ? 'page' : undefined}
            >
                Statistics
            </Link>
            <Link 
                to="/config"
                className={`${isActive('/config')} rounded-md px-3 py-2 text-sm font-medium transition-colors duration-200`}
                aria-current={location.pathname === '/config' ? 'page' : undefined}
            >
                Config
            </Link>
        </>
    );

    return (
        <nav className="bg-gray-800 border-b border-gray-700">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        {/* Mobile menu button */}
                        <div className="md:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="text-gray-300 hover:text-white p-2"
                            >
                                {isOpen ? <X size={24} /> : <Menu size={24} />}
                            </button>
                        </div>
                        
                        {/* Desktop menu */}
                        <div className="hidden md:block">
                            <div className="flex items-baseline space-x-4">
                                <NavLinks />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <span className="text-gray-300 text-sm">Live Trading Dashboard</span>
                        <button
                            onClick={() => setShowRestartModal(true)}
                            className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        >
                            Restart Server
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            {isOpen && (
                <div className="md:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <NavLinks />
                    </div>
                </div>
            )}

            {/* Restart Modal */}
            {showRestartModal && (
				<div 
					className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
					onClick={(e) => {
						if (e.target === e.currentTarget) {
							setShowRestartModal(false);
							setRestartPassword('');
						}
					}}
				>
					<div className="bg-gray-800 rounded-lg p-6 max-w-sm w-full border border-gray-700 shadow-xl">
						<h3 className="text-xl font-bold text-gray-100 mb-4">Restart Server</h3>
						<input
							type="password"
							value={restartPassword}
							onChange={(e) => setRestartPassword(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') handleRestart();
								if (e.key === 'Escape') {
									setShowRestartModal(false);
									setRestartPassword('');
								}
							}}
							placeholder="Enter password"
							className="w-full p-2 mb-4 bg-gray-700 text-gray-100 border border-gray-600 rounded focus:border-blue-500 focus:outline-none"
							autoFocus
						/>
						<div className="flex justify-end space-x-2">
							<button
								onClick={() => {
									setShowRestartModal(false);
									setRestartPassword('');
								}}
								className="px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded transition-colors duration-200"
							>
								Cancel
							</button>
							<button
								onClick={handleRestart}
								className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
							>
								Restart
							</button>
						</div>
					</div>
				</div>
			)}
        </nav>
    );
}